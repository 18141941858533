<template>
  <div id="app" class="d-flex justify-content-center align-items-center">
    <div class="card product-card">
      <img :src="image" alt="Card image" class="card-img-top">
      <div class="card-body">
        <h5 class="card-title">{{ title }}</h5>
        <div class="quantity-control">
          <button class="minus" @click="decrementQuantity">-</button>
          <span id="quantity" class="quantity">{{ quantity }}</span>
          <button class="plus" @click="incrementQuantity">+</button>
        </div>
      </div>
    </div>
   
    <div class="shipping-info-container">
      <div class="shipping-info">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Shipping Information</h5>
            <form>
              <div class="shipping-info-row">
                <label for="firstName" class="form-label">First Name:</label>
                <input type="text" class="form-control" id="firstName" v-model="firstName">
              </div>
              <div class="shipping-info-row">
                <label for="lastName" class="form-label">Last Name:</label>
                <input type="text" class="form-control" id="lastName" v-model="lastName">
              </div>
              <div class="shipping-info-row">
                <label for="streetAddress" class="form-label">Street Address:</label>
                <input type="text" class="form-control" id="streetAddress" v-model="streetAddress">
              </div>
              <div class="shipping-info-row">
                <label for="city" class="form-label">City:</label>
                <input type="text" class="form-control" id="city" v-model="city">
              </div>
              <div class="shipping-info-row">
                <label for="postalCode" class="form-label">Postal Code:</label>
                <input type="text" class="form-control" id="postalCode" v-model="postalCode">
              </div>
              <div class="shipping-info-row">
                <label for="phoneNumber" class="form-label">Phone Number:</label>
                <input type="tel" class="form-control" id="phoneNumber" v-model="phoneNumber">
              </div>
              <div class="shipping-info-row">
                <label for="email" class="form-label">Email:</label>
                <input type="text" class="form-control" id="email" v-model="email">
              </div>
            </form>
          </div>
        </div>
      </div>
      <p id="additional-text">Please make sure to fill out the shipping form</p>
    </div>

    <div id="payment-success" class="payment-success">
      <p style="font-size: x-large; color: green;">Order Successful. You will receive an email with your tracking info soon.</p>
    </div>

    <div id="payment-fail" class="payment-fail">
      <p style="font-size: x-large; color: red;">Order Failed. Refresh and try again.</p>
    </div>

    <div :class="{ 'greyed-out': !isShippingFormComplete }" id="paymentform">
      <form id="payment-form">
        <div id="card-container"></div>
        <button id="card-button" type="button" @click="handlePaymentClick" :disabled="!isShippingFormComplete">
          Pay ${{ totalPrice }}
        </button>
      </form>
    </div>
    <div id="freeShipping">
      <header>Free Shipping on Order $100+</header>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      appId: 'sandbox-sq0idb-Orj7PjYImsxomOSBmRXrnw',
      locationId: 'L9GZMNH5W40HT',
      image: require('@/assets/images/vapeimage.jpg'),
      title: 'ELF Bar BC5000 Puff Disposable Vape 50 mg - $19.99',
      quantity: 1,
      firstName: '',
      lastName: '',
      streetAddress: '',
      city: '',
      postalCode: '',
      phoneNumber: '',
      email: '',
      isPopupVisible: false,
      card: null,
      basePrice: 19.99,
      shippingCharge: 15,
    };
  },
  computed: {
    isShippingFormComplete() {
      return this.firstName && this.lastName && this.streetAddress && this.city && this.postalCode && this.phoneNumber && this.email;
    },
    totalPrice() {
      const total = Math.round((this.quantity * this.basePrice) / 10) * 10;
      return total < 100 ? total + this.shippingCharge : total;
    }
  },
  methods: {
    incrementQuantity() {
      this.quantity++;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    handlePaymentClick(event) {
      if (window.handlePaymentMethodSubmission) {
        window.handlePaymentMethodSubmission(event);
      }
    }
  }
};
</script>

<style scoped>

#freeShipping {
  margin-top: 470px;
  position: absolute;
  margin-right: 750px;
}

#app {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card {
  margin-left: 50px;
}

.shipping-info-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
}

#paymentform {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
}

.greyed-out {
  pointer-events: none; /* Disable interactions */
  opacity: 0.5; /* Make it look greyed out */
}

.payment-success {
  width: 700px;
  height: 100px;
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  visibility: hidden;
}

.payment-fail {
  width: 700px;
  height: 100px;
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  visibility: hidden;
}

.payment-success.visible,
.payment-fail.visible {
  visibility: visible;
  animation: jump 0.5s;
}

@media (max-width: 768px) {
  #app {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    padding: 20px;
  }

  #freeShipping {
  position: absolute;
  margin-top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  background-color: white;
  z-index: 10; /* Ensure it is above other elements */
}

  .product-card {
    margin-left: 50px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .shipping-info-container {
    margin-left: 0;
    margin-bottom: 20px;
  }

  #paymentform {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .shipping-info,
  .card {
    margin-left: 0;
  }
}

.shipping-info {
  margin-left: 50px;
}

.shipping-info-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.shipping-info-row input {
  flex-grow: 1;
  min-width: 0;
  width: auto;
}

.form-label {
  width: 120px;
  margin-right: 10px;
}

.card {
  width: 18rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin-left: 50px;
}

.card-img-top {
  width: 100%;
  height: auto;
}

.card-body {
  padding: 15px;
}

.card-title {
  margin-bottom: 10px;
}

.btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100px;
  margin-top: 10px;
  height: 50px;
  font-size: 13px;
  font-weight: 350;
  margin-left: 50px;
}

.btn:hover {
  background-color: #0056b3;
}

.plus {
  margin-left: 10px;
}

.minus {
  margin-right: 10px;
}

.shipping-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buy-now {
  margin-top: 20px;
}

.popup-card {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  z-index: 1000;
}

.popup-content {
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

@keyframes jump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

#additional-text {
  margin-top: 10px;
  margin-left: 100px;
  color: red;
  visibility: hidden;
  transition: visibility 0s, opacity 0.5s linear;
}

#additional-text.visible {
  visibility: visible;
  animation: jump 0.5s;
}
</style>
